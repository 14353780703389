import React from 'react';
import classnames from 'classnames';

import Row from './Row';
import Column from './Column';

export interface Props {
  className?: string;
}

export default class Grid extends React.PureComponent<Props> {
  static Row = Row;
  static Column = Column;

  render() {
    return <div {...this.props} className={classnames('relative', this.props.className)} />;
  }
}

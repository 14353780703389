import React from 'react';

export interface Options<K, T> {
  load: (id?: K) => Promise<T>;
  id?: K;
}

interface State<T> {
  value?: T;
  loading: boolean;
  error?: string;
}

export interface Return<T> extends State<T> {
  reload: () => any;
}

const useLoad = <K, T>(options: Options<K, T>): Return<T> => {
  const [state, setState] = React.useState<State<T>>({
    loading: true
  });

  const load = () =>
    options
      .load(options.id)
      .then((result) => setState({ value: result, loading: false }))
      .catch((error) =>
        setState({
          error: error && error.message ? error.message : 'Unknown load error.',
          loading: false
        })
      );

  const reload = () => {
    setState({ loading: true });
    load();
  };

  React.useEffect(reload, [options.id]);

  return { value: state.value, loading: state.loading, error: state.error, reload };
};

export default useLoad;

import React from 'react';
import classnames from 'classnames';

import { getStylesWithSize } from '@/util';
import { WithSize, Magnitude } from '@/constants';

import style from './Column.sass';

export interface Props extends WithSize<Magnitude> {
  padded?: boolean;
  right?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const Column: React.FC<Props> = ({ padded, ...props }) => (
  <div
    style={props.style}
    className={classnames(
      getStylesWithSize(
        (size) => (style as any)[`${size}${props[size] || 0}`],
        (size) => props[size] != null
      ),
      {
        'p-3': padded,
        [style.right]: props.right
      },
      props.className
    )}
  >
    {props.children}
  </div>
);

export default Column;

import React from 'react';
import classnames from 'classnames';

export interface Props {
  className?: string;
}

export interface Props extends React.DOMAttributes<any> {
  is?: any;
  padded?: boolean;
  className?: string;
}

const Row: React.FC<Props> = ({ is: Component = 'div', padded, className, ...rest }) => (
  <Component {...rest} className={classnames('relative flex flex-wrap', { 'px-5': padded }, className)} />
);

export default Row;

import React from 'react';

import useLoad, { Options, Return } from '@/hooks/useLoad';

export interface Props<K, T> extends Options<K, T> {
  children: (props: RenderProps<T>) => React.ReactElement;
}

export type RenderProps<T> = Return<T>;

const LoadData = <T, K>({ children, ...props }: React.PropsWithChildren<Props<K, T>>) => {
  const { value, loading, error, reload } = useLoad(props);

  return children({
    value: value,
    loading,
    error,
    reload
  });
};

export default LoadData;

import React from 'react';

import { Props as InputProps } from '@/components/Input';
import { Type } from '@/components/Icon';
import { withValidation } from '@/components/hoc/withValidation';

import TextInput from '../TextInput';

export interface Props extends Omit<InputProps, 'type'> {
  invalid?: boolean;
  label?: string;
  leadingIcon?: Type;
  icon?: Type;
  inputClassName?: string;
}

const PasswordInput = (props: React.PropsWithChildren<Props>) => {
  const [type, setType] = React.useState<'password' | 'text'>('password');

  return (
    <TextInput
      {...props}
      type={type}
      icon="eye"
      onIconClick={() => setType((type) => (type === 'password' ? 'text' : 'password'))}
    />
  );
};

export default PasswordInput;
export const PasswordInputWithValidation = withValidation(PasswordInput);
